import React from "react";

const Stats = () => {
    return(
        <>
        <h2 className="intro-y text-2xl font-medium mt-10 text-center mr-auto">
        Vehicle Accident Reports
        </h2>
        <div className="flex mt-10 container">
                    <div className="tab-content">
                        <div id="layout-1-monthly-fees" className="tab-pane flex flex-col lg:flex-row active" role="tabpanel" aria-labelledby="layout-1-monthly-fees-tab">
                            <div className="intro-y flex justify-center flex-col flex-1 text-center sm:px-10 lg:px-5 pb-10 lg:pb-0">
                                <div className="font-medium text-lg">Why do I need a vehicle history report?</div>
                                <div className="mt-3 lg:text-justify text-slate-600 dark:text-slate-500">
                                    <p>Unfortunately, every month, <b>hundreds</b> of cars in Kenya are involved in accidents and get <b>written-off</b> by insurance companies.</p>
                                    <p className="mt-2">After compensating the owners of such cars, the insurance companies will go ahead a resell these written off cars in auctions. These cars are then bought by third parties who sometimes break them down for parts.</p>
                                    <p className="mt-2">However, most of the time, these <b>written-off</b> cars are usually restored mostly by quack mechanics and sold off to unsuspecting buyers branded with the term <b>Accident Free</b>. Everyday unsuspecting buyers are purchasing <b>salvaged</b> carswithout their knowledge, this puts them in danger since these cars are not always restored to their original state.</p>
                                    <p className="mt-2"><b>Seatbelt Pretensioners, Airbags, Accident Sensors</b> are usually most of the ignored parts when restoring these cars with focus being on body work and engine. This <b>is</b> actually dangerous as these features have been put in place to prevent injuries in accidents.</p>
                                    <p className="mt-2"><b>GariSure</b> currently hosts growing database of over a thousand vehicle <b>accident reports</b> from various insurance companies within Kenya and provides this for free in an extremely intuitive interface.</p>
                                </div>
                            </div>
                            <div className="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0">
                                <i data-lucide="briefcase" className="block w-12 h-12 text-primary mx-auto"></i> 
                                <div className="flex justify-center">
                                    <div className="relative text-5xl font-semibold mt-8 mx-auto"> 1,000 </div>
                                </div>
                                <div className="text-xl font-medium text-center mt-10">Salvaged Vehicles</div>
                                <div className="text-slate-600 dark:text-slate-500 text-center mt-5"> 10 Insurance Companies </div>
                                <div className="text-slate-500 px-10 text-center mx-auto mt-2">We currently have over 1,000 salvaged vehicle reports sitting in our databases with the number growing every week.</div>
                            </div>
                            <div className="intro-y flex-1 box py-16 lg:ml-5">
                                <i data-lucide="shopping-bag" className="block w-12 h-12 text-primary mx-auto"></i> 
                                <div className="flex justify-center">
                                    <div className="relative text-5xl font-semibold mt-8 mx-auto">  0.3% </div>
                                </div>
                                <div className="text-xl font-medium text-center mt-10">Hit Rate</div>
                                <div className="text-slate-600 dark:text-slate-500 text-center mt-5"> 1,000 Searches <span className="mx-1">•</span> 3 Hits </div>
                                <div className="text-slate-500 px-10 text-center mx-auto mt-2">Out of every 1,000 searches that are done on <b>GariSure</b>, 3 of them are usually salvaged cars.</div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Stats