
import { Button, TextField } from "@material-ui/core";
import { useRef, useState } from "react";
import {useDropzone} from 'react-dropzone';
import {Loading} from 'react-fullscreen-loading';
import { json } from "react-router-dom";
import Notification from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const NewCar = () => {

    const accessToken = localStorage.getItem("accessToken");
    const isLoggedIn = accessToken !== null;

    if (!isLoggedIn) {
        window.location.href = "/";
    }

    const [showLoader, setShowLoader] = useState(false);
    const [showResponse, setResponse] = useState("Request processed succesfully");
    const [responseTitle, setResponseTitle] = useState("Success");
    const [numberPlate, setNumberPlate] = useState();
    const [make, setMake] = useState();
    const [model, setModel] = useState();
    const [color, setColor] = useState();
    const [yearManufacture, setYom] = useState('Not Available');
    const [date, setDate] = useState();
    const [insurance, setInsurance] = useState();
    const [chassisNumber, setChasisNumber] = useState('Not Available');
    const [engineNumber, setEngineNumber] = useState('Not Available');
    const [mileage, setMileage] = useState('Not Available');
    const [images] = useState([]);

    const successNotification = useRef();
    const successNotificationToggle = () => {
        // Show notification
        successNotification.current?.showToast();
      };

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
      } = useDropzone({    
        maxFiles:5,
        multiple:true,
        onDrop: acceptedFiles => {
            images.push(acceptedFiles[0])
        }
      });
    
      async function createCar() {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + accessToken );
        
        var formdata = new FormData();
        images.forEach(image => {
            formdata.append("images", image, image.name);
        });
        formdata.append("vehicle", '{\n    \"make\": \"' + make + '\",\n    \"model\": \"' + model + '\",\n    \"color\": \"' + color + '\",\n    \"numberPlate\": \"' + numberPlate + '\",\n    \"yearManufacture\": \"' + yearManufacture + '\",\n    \"chassisNumber\": \"' + chassisNumber + '\",\n    \"engineNumber\": \"' + engineNumber + '\",\n    \"mileage\": \"' + mileage + '\",\n    \"insurer\": {\n        \"name\": \"' + insurance + '\"   }\n}');


        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(process.env.REACT_APP_BASE_URL + "public/vehicles", requestOptions)
          .then(response => response.text())
          .then(result => {
              const jsonResponse = JSON.parse(result);
              if (jsonResponse.error) {
                  console.log(jsonResponse.error)
                  setResponse(jsonResponse.error);
                  setResponseTitle("ERROR")
              }
              successNotification.current?.showToast()
              setShowLoader(false);
            })
          .catch(error => console.log('error', error));

        
      }
      const acceptedFileItems = acceptedFiles.map(file => (
        <li key={images.length}>You have selected {images.length} out of 5 images</li>
      ));
    
      const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
       return (
         <li key={file.path}>
              {file.path} - {file.size} bytes
              <ul>
                {errors.map(e => <li key={e.code}>{e.message}</li>)}
             </ul>
    
         </li>
       ) 
      });
    const handleFormSubmission = async e => {
        e.preventDefault();
        setShowLoader(true);
        const response = await createCar();
    }
    return (
        <>
        {showLoader && (
            <>
            <div class="loading">Loading&#8230;</div>
            </>
        )}
                    <div className="text-center">
                      {/* BEGIN: Notification Content */}
                      <Notification
                        getRef={(el) => {
                          successNotification.current = el;
                        }}
                        className="flex"
                      >
                      <Lucide icon="CheckCircle" className="text-success" />
                        <Lucide icon="AlertCircle" className="text-warning" />
                        <div className="ml-4 mr-4">
                          <div className="font-medium">{responseTitle}!</div>
                          <div className="mt-1 text-slate-500">
                            {showResponse}
                          </div>
                        </div>
                      </Notification>
                      {/* END: Notification Content */}
                    </div>
        <div className="intro-y box p-5">
            <form  noValidate onSubmit={handleFormSubmission}>
                <div>
                    <label htmlFor="number-plate" className="form-label">Vehicle Details</label>
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="number-plate"
                    name="number-plate"
                    label="Number Plate"
                    onChange={e => setNumberPlate(e.target.value)}
                    inputProps={{ className: "form-control w-full" }} 
                    />
                </div>
                <div className="mt-3">
                    <div className="sm:grid grid-cols-3 gap-2">
                        <div className="input-group">
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="make"
                            name="make"
                            label="Make"
                            onChange={e => setMake(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                        <div className="input-group mt-2 sm:mt-0">
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="model"
                            name="model"
                            label="Model"
                            onChange={e => setModel(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                        <div className="input-group mt-2 sm:mt-0">
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="color"
                            name="color"
                            label="Color"
                            onChange={e => setColor(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="sm:grid grid-cols-3 gap-2">
                        <div className="input-group">
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="yom"
                            name="yom"
                            label="Y.O.M"
                            onChange={e => setYom(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <label className="form-label">Accident Details</label>
                    <div className="sm:grid grid-cols-3 gap-2">
                        <div className="input-group">
                            <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="date"
                            name="date"
                            onChange={e => setDate(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                        <div className="input-group mt-2 sm:mt-0">
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="insurance"
                            name="insurance"
                            label="Insurance"
                            onChange={e => setInsurance(e.target.value)}
                            inputProps={{ className: "form-control" }} 
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>(5 files are the maximum number of files you can drop here)</em>
                    </div>
                    <aside>
                        <ul>{acceptedFileItems}</ul>
                    </aside>
                </section>
                </div>
                <div className="text-right mt-5">
                    <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary w-24"  
                    >
                    Save
                    {showLoader && (
                    <svg width="25" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" className="w-8 h-8">
                        <g fill="none" fillRule="evenodd" strokeWidth="4">
                            <circle cx="22" cy="22" r="1">
                                <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                            </circle>
                            <circle cx="22" cy="22" r="1">
                                <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                            </circle>
                        </g>
                    </svg>
                    )}
                    </Button>
                </div>
            </form>
        </div>
        </>
    )
}
export default NewCar