
import React from 'react';
import Header from './Header'
import Stats from './Stats'
import Faqspage from '../faqs/Faqspage';

const Homepage = () => {
    return(
        <>
        <Header />
        <Stats />
        <Faqspage />
        </>
    )
}
export default Homepage