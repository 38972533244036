import React from "react";
import { Home, FileQuestion, FileBadge2, PlusSquare, ListEnd } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import logo from '../../dist/images/garisure.png'

const TopBar = () => {
    const accessToken = localStorage.getItem("accessToken");
    const isLoggedIn = accessToken !== null;
    return (
        <>
        <div className="top-bar-boxed border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:mx-0 px-3 sm:px-8 md:px-6 mb-10 md:mb-8">
        <div className="h-full flex items-center">
            <a  href="/" className="-intro-x  md:flex">
            <img className="tooltip rounded-full w-40" src={logo} title="Header Image"/> 
            </a>
            <nav aria-label="breadcrumb" className="-intro-x h-full mr-auto">
                <ol className="breadcrumb breadcrumb-light">
                    <li className="breadcrumb-item"><a  href="!#">Your one stop shop for Kenyan vehicle report.</a></li>
                </ol>
            </nav>
        </div>
    </div>

<nav className="top-nav">
<ul>
    <li>
        <NavLink to="/" className={(navData) => (navData.isActive ? 'top-menu top-menu--active' : 'top-menu')}>
            <div className="top-menu__icon"> <Home size={24} /> </div>
            <div className="top-menu__title"> Home  </div>
        </NavLink>
    </li>
    <li>
        <NavLink to={{ pathname: "/faqs"}} className={(navData) => (navData.isActive ? 'top-menu top-menu--active' : 'top-menu')}>
            <div className="top-menu__icon"> <FileQuestion size={24} /> </div>
            <div className="top-menu__title"> FAQs </div>
        </NavLink>
    </li>
    <li>
        <NavLink to="/reports" className={(navData) => (navData.isActive ? 'top-menu top-menu--active hide' : 'top-menu hide')}>
                        <div className="top-menu__icon"> <FileBadge2 size={24} /> </div>
            <div className="top-menu__title"> Sample Report </div>
        </NavLink>
    </li>
    {isLoggedIn
     ?
     <>
        <li>
            <NavLink to="/add-car" className={(navData) => (navData.isActive ? 'top-menu top-menu--active' : 'top-menu')}>
                            <div className="top-menu__icon"> <PlusSquare size={24} /> </div>
                <div className="top-menu__title"> Add Car </div>
            </NavLink>
        </li>
        <li>
            <NavLink to="/cars" className={(navData) => (navData.isActive ? 'top-menu top-menu--active' : 'top-menu')}>
                            <div className="top-menu__icon"> <ListEnd size={24} /> </div>
                <div className="top-menu__title"> Listed Cars </div>
            </NavLink>
        </li>
     </>
    :
    <></>
    }

</ul>
</nav>
</>
    );
}

export default TopBar;