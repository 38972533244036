import { CheckSquare, SearchIcon } from "lucide-react"
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

const ListedCars = () => {
    const accessToken = localStorage.getItem("accessToken");
    const isLoggedIn = accessToken !== null;
    if (!isLoggedIn) {
        window.location.href = "/";
    }

    const [showLoader, setShowLoader] = useState(false);
    const [carData, setCarData] = useState([]);
    useEffect(() => {
        console.log("About to call fetch cars")
        const cars = fetchCars();
    }, [setCarData])

    async function fetchCars() {
        setShowLoader(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", 'Bearer ' + accessToken );
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
        fetch(process.env.REACT_APP_BASE_URL + "public/vehicles", requestOptions)
          .then(response => response.text())
          .then(result => {
            setShowLoader(false);
              const jsonData = JSON.parse(result);
              if (jsonData.hasOwnProperty('error')){
                window.location.href = "/login";
                localStorage.removeItem("accessToken");
              }
              setCarData(jsonData.data);
          })
          .catch(error => {
              console.log('error', error)
          });
    }
    const carList = carData.map(car => {
        return (
            <tr className="intro-x">
                <td className="w-40">
                    <div className="flex">
                        <div className="w-10 h-10 image-fit zoom-in">
                            <img alt={car.numberPlate} className="tooltip rounded-full" src={car.vehicleImages[0].url} title="Uploaded at 3 June 2022"/>
                        </div>
                    </div>
                </td>
                <td>
                    <a href="" className="font-medium whitespace-nowrap">{car.numberPlate}</a> 
                    <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">{car.make}, {car.model}</div>
                </td>
                <td className="text-center">{car.insurer.name}</td>
                <td className="w-40">
                    <div className="flex items-center justify-center"> <i data-lucide="check-square" className="w-4 h-4 mr-2"></i> {car.yearManufacture} </div>
                </td>
                <td className="table-report__action w-56">
                    <div className="flex justify-center items-center">
                        <Link to={`/report/${car.numberPlate}`} state={{ numberPlate: car.numberPlate }} className="flex items-center mr-3"><CheckSquare className="w-4 h-4 mr-1"></CheckSquare>View</Link>
                        <a className="flex items-center mr-3" href="javascript:;"> <i data-lucide="check-square" className="w-4 h-4 mr-1"></i> Edit </a>
                        <a className="flex items-center text-danger" href="javascript:;" data-tw-toggle="modal" data-tw-target="#delete-confirmation-modal"> <i data-lucide="trash-2" className="w-4 h-4 mr-1"></i> Delete </a>
                    </div>
                </td>
            </tr>
        )
    })
    return (
        <>
        <h2 className="intro-y text-lg font-medium mt-10">
                    Listed
                </h2>
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                        <button className="btn btn-primary shadow-md mr-2">Add New Vehicle</button>
                        <div className="hidden md:block mx-auto text-slate-500">Showing 1 to 10 of {carData.length} entries</div>
                        <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                            <div className="w-56 relative text-slate-500">
                                <input type="text" className="form-control w-56 box pr-10" placeholder="Search..."/>
                                <CheckSquare className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                            </div>
                        </div>
                    </div>
                    <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                        <table className="table table-report -mt-2">
                            <thead>
                                <tr>
                                    <th className="whitespace-nowrap"></th>
                                    <th className="whitespace-nowrap">NUMBER PLATE</th>
                                    <th className="text-center whitespace-nowrap">INSURANCE</th>
                                    <th className="text-center whitespace-nowrap">YEAR OF MANUFACTURE</th>
                                    <th className="text-center whitespace-nowrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                            {showLoader && (
                                <svg width="25" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" className="w-8 h-8">
                                    <g fill="none" fillRule="evenodd" strokeWidth="4">
                                        <circle cx="22" cy="22" r="1">
                                            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                        </circle>
                                        <circle cx="22" cy="22" r="1">
                                            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                        </circle>
                                    </g>
                                </svg>
                                )}
                                {carList}
                            </tbody>
                        </table>
                    </div>
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                        <nav className="w-full sm:w-auto sm:mr-auto">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a className="page-link" href="#"> <i className="w-4 h-4" data-lucide="chevrons-left"></i> </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#"> <i className="w-4 h-4" data-lucide="chevron-left"></i> </a>
                                </li>
                                <li className="page-item"> <a className="page-link" href="#">1</a> </li>
                                <li className="page-item active"> <a className="page-link" href="#">2</a> </li>
                                <li className="page-item"> <a className="page-link" href="#">3</a> </li>
                                <li className="page-item">
                                    <a className="page-link" href="#"> <i className="w-4 h-4" data-lucide="chevron-right"></i> </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#"> <i className="w-4 h-4" data-lucide="chevrons-right"></i> </a>
                                </li>
                            </ul>
                        </nav>
                        <select className="w-20 form-select box mt-3 sm:mt-0">
                            <option>10</option>
                            <option>25</option>
                            <option>35</option>
                            <option>50</option>
                        </select>
                    </div>
                </div>
                <div id="delete-confirmation-modal" className="modal" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="p-5 text-center">
                                    <i data-lucide="x-circle" className="w-16 h-16 text-danger mx-auto mt-3"></i> 
                                    <div className="text-3xl mt-5">Are you sure?</div>
                                    <div className="text-slate-500 mt-2">
                                        Do you really want to delete these records? 
                                        <br/>
                                        This process cannot be undone.
                                    </div>
                                </div>
                                <div className="px-5 pb-8 text-center">
                                    <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary w-24 mr-1">Cancel</button>
                                    <button type="button" className="btn btn-danger w-24">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default ListedCars