import React from "react";

const Footer = () => {
    return(
        <>
    <div className="top-bar-boxed border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:mx-0 px-3 sm:px-8 md:px-6 mb-10 md:mb-8">
        <div className="h-full flex items-center">
            <a  href="!#" className="-intro-x hidden md:flex">
                <span className="text-white text-lg ml-3 bold"> GariSure </span> 
            </a>
            <nav aria-label="breadcrumb" className="-intro-x h-full mr-auto">
                <ol className="breadcrumb breadcrumb-light">
                    <li className="breadcrumb-item"><a  href="!#">Terms & Conditions apply. <br /> We are not in any way advising against proper and safe restoration of accident cars, however we would like to ask sellers to disclose this upfront when selling such units.</a></li>
                </ol>
            </nav>
        </div>
    </div>
        </>
    );
}

export default Footer