import React, { useEffect } from "react";
import "@left4code/tw-starter/dist/js/accordion"
import { useLocation } from "react-router-dom";

const Faqspage = (props) => {
    const { pathname } = useLocation();
    useEffect(() => {
      });
    if (pathname !== '/faqs'){
        return(
            <></>
        )
    }else {
        return(
            <>
             <h2 id="faq-section" className="intro-y text-2xl font-medium mt-10 text-center mr-auto">
            Frequently Asked Questions
            </h2>
            <div className="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
                        <div className="intro-y box lg:mt-5">
                            <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="font-medium text-base mr-auto">
                                    About Our Products
                                </h2>
                            </div>
                            <div id="faq-accordion-1" className="accordion accordion-boxed p-5">
                                <div className="accordion-item">
                                    <div id="faq-accordion-content-1" className="accordion-header">
                                        <button className="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-1" aria-controls="faq-accordion-collapse-1"> OpenSSL Essentials: Working with SSL Certificates, Private Keys </button>
                                    </div>
                                    <div id="faq-accordion-collapse-1" className="accordion-collapse collapse" aria-labelledby="faq-accordion-content-1" data-tw-parent="#faq-accordion-1">
                                        <div className="accordion-body text-slate-600 dark:text-slate-500 leading-relaxed"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div id="faq-accordion-content-1" className="accordion-header">
                                        <button className="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-2" aria-controls="faq-accordion-collapse-2"> OpenSSL Essentials: Working with SSL Certificates, Private Keys </button>
                                    </div>
                                    <div id="faq-accordion-collapse-2" className="accordion-collapse collapse" aria-labelledby="faq-accordion-content-2" data-tw-parent="#faq-accordion-2">
                                        <div className="accordion-body text-slate-600 dark:text-slate-500 leading-relaxed"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div id="faq-accordion-content-1" className="accordion-header">
                                        <button className="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-4" aria-controls="faq-accordion-collapse-4"> OpenSSL Essentials: Working with SSL Certificates, Private Keys </button>
                                    </div>
                                    <div id="faq-accordion-collapse-4" className="accordion-collapse collapse" aria-labelledby="faq-accordion-content-4" data-tw-parent="#faq-accordion-4">
                                        <div className="accordion-body text-slate-600 dark:text-slate-500 leading-relaxed"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div id="faq-accordion-content-1" className="accordion-header">
                                        <button className="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-3" aria-controls="faq-accordion-collapse-3"> OpenSSL Essentials: Working with SSL Certificates, Private Keys </button>
                                    </div>
                                    <div id="faq-accordion-collapse-3" className="accordion-collapse collapse" aria-labelledby="faq-accordion-content-3" data-tw-parent="#faq-accordion-3">
                                        <div className="accordion-body text-slate-600 dark:text-slate-500 leading-relaxed"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
}

export default Faqspage