import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import Button from "../../base-components/Button";

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_BASE_URL + "auth/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showLoader, setShowLoader] = useState(false);

    const handleFormSubmission = async e => {
        e.preventDefault();
        setShowLoader(true);
        const response = await loginUser({
          email,
          password
        });
        setShowLoader(false);
        if ('idToken' in response['data']) {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            localStorage.setItem('accessToken', response['data']['idToken']);
            window.location.href = "/cars";
          });
        } else {
          swal("Failed", response.message, "error");
        }
    }
    return(
        <div className="container sm:px-10">
            <div className="block xl:grid grid-cols-2 gap-4">
                
                <div className="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                    <div className="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                        <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                            Sign In
                        </h2>
                        <div className="intro-x mt-2 text-slate-400 xl:hidden text-center">A few more clicks to sign in to your account. Manage all your e-commerce accounts in one place</div>
                            <form  noValidate onSubmit={handleFormSubmission}>
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                onChange={e => setEmail(e.target.value)}
                                inputProps={{ className: "intro-x login__input form-control py-3 px-4 block" }} 
                                />
                                <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                onChange={e => setPassword(e.target.value)}
                                inputProps={{ className: "intro-x login__input form-control py-3 px-4 block" }} 
                                />
                                <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"  
                                >
                                {showLoader && (
                                <svg width="25" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" className="w-8 h-8">
                                    <g fill="none" fillRule="evenodd" strokeWidth="4">
                                        <circle cx="22" cy="22" r="1">
                                            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                        </circle>
                                        <circle cx="22" cy="22" r="1">
                                            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                        </circle>
                                    </g>
                                </svg>
                                )}
                                Sign In
                                </Button>
                            </form>
                        <div className="intro-x mt-10 xl:mt-24 text-slate-600 dark:text-slate-500 text-center xl:text-left"> By signin up, you agree to our <a className="text-primary dark:text-slate-200" href="">Terms and Conditions</a> & <a className="text-primary dark:text-slate-200" href="">Privacy Policy</a> </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login