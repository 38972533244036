
import { Calendar, CarIcon, FileClock, Hash, Palette, RectangleHorizontal } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TinySlider from "tiny-slider-react";
import Lucide from "../../base-components/Lucide";
import {
    PreviewComponent,
    Preview,
    Source,
    Highlight,
  } from "../../base-components/PreviewComponent";
  import { useLocation, useParams } from 'react-router-dom';
  import { useRef } from 'react';
  import _ from "lodash";

const Reports = (props) => {
    let location = useLocation();
    const urlParams = useParams();
    const [carData, setCarData] = useState([]);
    const [insuranceData, setInsuranceData] = useState({name: ''});
    const [carImages, setCarImages] = useState([]);
    let auctionEndDate = "";

    const settings = {
      lazyload: true,
      nav: false,
      mouseDrag: false,
      loop: true,
      items: 1,
      gutter: 5,
      responsive: {
        420: {
          items: 2
        }
      }
    };

    const imgStyles = {
      width: "100%",
      height: "413px",
      objectFit: "cover"
    };
    
    useEffect(() => {
        const cars = fetchCar();
    }, [setCarData, setCarImages])

    async function fetchCar() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
        fetch(process.env.REACT_APP_BASE_URL + "public/vehicles/numberplate/" + urlParams.numberplate , requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(result);
              let jsonData = JSON.parse(result)[0];
              setCarData(jsonData);
              setCarImages(jsonData.vehicleImages);
              setInsuranceData(jsonData.insurer);
              const d = jsonData.auctionDate;
              auctionEndDate = d.replace("Ended on", "Auction Date");
              console.log(auctionEndDate);
          })
          .catch(error => {
              console.log('error', error)
          });
    }




    const carImagesComponent = carImages.map(image => (
        <>
        {
          //  imgUrl = image.url.replace("http:", "https:")

        }
                    <img src={image.url} key={image.imageName}/>
        </>
      ));

    return(
        <>
      <div className='container'>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="col-span-12 intro-y lg:col-span-8">
          {/* BEGIN: Single Item */}
          <PreviewComponent className="intro-y box">
            {({ toggle }) => (
              <>
                <div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
                  <h2 className="mr-auto text-base font-medium">
                  {carImages.length} Crash Images
                  </h2>
                </div>
                <div className="p-5">
                  <Preview>
                    <div className="mx-6">
                    <TinySlider settings={settings}>
                      {
                        carImages.map((image, index) => (
                          <div key={index}>
                            <img
                              className={`tns-lazy-img image-contain`}
                              data-src={image.url}
                              style={imgStyles}
                            />
                          </div>
                        ))
                      }
                      
                    </TinySlider>
                    </div>
                  </Preview>
                  
                </div>
              </>
            )}
          </PreviewComponent>
          {/* END: Single Item */}

         
        </div>
        <div className="col-span-12 intro-y lg:col-span-4">
          {/* BEGIN: Center Mode */}
          <PreviewComponent className="intro-y box">
            {({ toggle }) => (
              <>
                <div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400">
                  <h2 className="mr-auto text-base font-medium">Information</h2>
                </div>
                <div className="p-5 pt-0">
                  <div className="flex-1 px-5 pt-5 lg:mt-0 lg:pt-0 border-b sm:flex-row border-slate-200/60 dark:border-darkmode-400 pb-5">
                    <div className="font-medium text-center lg:text-left lg:mt-3">
                      Car Details
                    </div>
                    <div className="flex flex-col items-center justify-center mt-2 ml-2 lg:items-start">
                      <div className="flex items-center truncate sm:whitespace-normal">
                        <Lucide icon="Car" className="w-4 h-4 mr-2" />
                        {carData.make + " " + carData.model}
                        {(carData.bodyType !== "Not Available") ? " - " + carData.bodyType : ""}
                      </div>
                      <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Hash" className="w-4 h-4 mr-2" /> 
                        Number Plate: {carData.numberPlate}
                      </div>
                      {(carData.color !== "Not Available") ?
                        <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Palette" className="w-4 h-4 mr-2" /> 
                        Color: {carData.color}
                      </div> : ""
                      }
                      <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Hash" className="w-4 h-4 mr-2" /> 
                        Chasis Number: {carData.chassisNumber}
                      </div>
                      <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Calendar" className="w-4 h-4 mr-2" /> 
                        YOM: {carData.yearManufacture}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 px-5 pt-5 lg:mt-0 lg:pt-0 border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
                    <div className="font-medium text-center lg:text-left lg:mt-3">
                      Insurance Details
                    </div>
                    <div className="flex flex-col items-center justify-center mt-2 ml-2 lg:items-start">
                      <div className="flex items-center truncate sm:whitespace-normal">
                        <Lucide icon="Landmark" className="w-4 h-4 mr-2" />
                        {insuranceData.name}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 px-5 pt-5 lg:mt-0 lg:pt-0 border-slate-200/60 dark:border-darkmode-400 pb-5">
                    <div className="font-medium text-center lg:text-left lg:mt-3">
                      Auction Details
                    </div>
                    <div className="flex flex-col items-center justify-center mt-2 ml-2 lg:items-start">
                      <div className="flex items-center truncate sm:whitespace-normal">
                        <Lucide icon="Calendar" className="w-4 h-4 mr-2" />
                        Date Of Loss: {carData.lossDate}
                      </div>
                      <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Calendar" className="w-4 h-4 mr-2" /> 
                        {carData.auctionDate}
                      </div>
                      <div className="flex items-center mt-3 truncate sm:whitespace-normal">
                        <Lucide icon="Coins" className="w-4 h-4 mr-2" />
                        {carData.winningBid}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </PreviewComponent>
          {/* END: Center Mode */}
        </div>
      </div>
      </div>

       
        </>
    )
}
export default Reports