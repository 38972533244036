import '.././dist/css/app.css';
import '.././dist/css/_app.css';
import TopBar from './global/TopBar';
import React from 'react';
import Footer from './global/Footer';
import Homepage from './home/Homepage'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Reports from './reports/Reports';
import Faqspage from './faqs/Faqspage';
import Login from './login/Login';
import ListedCars from './admin/ListedCars';
import NewCar from './admin/NewCar';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <TopBar />
      <div className="content content--top-nav">
          <Routes>
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/faqs" element={<Faqspage />}></Route>
            <Route path="/report/:numberplate" element={<Reports />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/cars" element={<ListedCars />}></Route>
            <Route path="/add-car" element={<NewCar />}></Route>
          </Routes>
      </div>
        <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
