import {React, useState} from "react";
import header from '../../dist/images/header_image.png';
import Lucide from "../../base-components/Lucide"
import Tippy from "../../base-components/Tippy";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
import {
    PreviewComponent,
    Preview,
    Source,
    Highlight,
  } from "../../base-components/PreviewComponent";
  import { Tab } from "../../base-components/Headless";
  import Button from "../../base-components/Button";
  import { Menu, Dialog } from "../../base-components/Headless";
  import { useRef } from 'react';

  async function searchCar(numberPlate) {
    const queryString = `?search_term=1c83e27d3993&email=anonymous4me321@gmail.com&search_type=plate=${numberPlate}`;
    const boundary = '---------------------------' + Date.now().toString(16);
    return fetch(process.env.REACT_APP_BASE_URL + "public/search" + queryString, {
      method: 'GET',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${boundary}`
      },
    })
      .then(data => data.json())
   }

const Header = () => {
    const [basicModalPreview, setBasicModalPreview] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [numberPlate, setNumberPlate] = useState("");

    const handleFormSubmission = async e => {
        e.preventDefault();
        let sanitizedPlates = numberPlate.replace(/\s+/g, '');
        if (sanitizedPlates.length < 6) {
            alert("Number plate must be at least 6 characters long. Eg KAA 001A");
            return;
        }
        setShowLoader(true);
        const response = await searchCar(sanitizedPlates);
        setShowLoader(false);
        console.log(response);
        if (response.status === 200) {
            if (response.data === null) {
                alert("An error occurred. Please try again later");
                return;
            }
            else if (response.data.length === 0) {
                alert("No records found for this number plate");
                return;
            }else{
                localStorage.setItem('carData', JSON.stringify(response.data));
                window.location.href = `/report/${sanitizedPlates}`;
            }
        }
        
    }


    return (
        
    <div>
        <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
                <div className="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <br/>
                        <div className="items-center my-auto mx-auto xl:bg-transparent rounded-md shadow-md xl:shadow-none w-full mt-4">
                            <h3 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-center mt-4">
                                Your Next Car's Hidden Past
                            </h3>
                            <div className="intro-x">
                                <div className="flex items-center justify-center">
                                    <img className="tooltip rounded-full w-30" src={header} title="Header Image"/>
                                </div>
                                {/* BEGIN: General Report */}
                                <div className="col-span-12 container">
                                    <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                                        <div
                                        className={clsx([
                                            "relative zoom-in",
                                            "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                                        ])}
                                        >
                                        <div className="p-5 box">
                                            <div className="flex">
                                            <Lucide
                                                icon="Search"
                                                className="w-[28px] h-[28px] text-primary"
                                            />
                                            <div className="ml-auto">
                                                <Tippy
                                                as="div"
                                                className="cursor-pointer bg-success py-[3px] flex rounded-full text-white text-xs pl-2 pr-1 items-center font-medium"
                                                content="33% Higher than last month"
                                                >
                                                <Lucide icon="ChevronUp" className="w-4 h-4 ml-0.5" />
                                                </Tippy>
                                            </div>
                                            </div>
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                            710
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                            Unique Car Searches
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                                        <div
                                        className={clsx([
                                            "relative zoom-in",
                                            "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                                        ])}
                                        >
                                        <div className="p-5 box">
                                            <div className="flex">
                                            <Lucide
                                                icon="CreditCard"
                                                className="w-[28px] h-[28px] text-pending"
                                            />
                                            <div className="ml-auto">
                                                <Tippy
                                                as="div"
                                                className="cursor-pointer bg-danger py-[3px] flex rounded-full text-white text-xs pl-2 pr-1 items-center font-medium"
                                                content="2% Lower than last month"
                                                >
                                                <Lucide
                                                    icon="ChevronDown"
                                                    className="w-4 h-4 ml-0.5"
                                                />
                                                </Tippy>
                                            </div>
                                            </div>
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                            0.3 %
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                            Hit Rate
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                                        <div
                                        className={clsx([
                                            "relative zoom-in",
                                            "before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70",
                                        ])}
                                        >
                                        <div className="p-5 box">
                                            <div className="flex">
                                            <Lucide
                                                icon="Monitor"
                                                className="w-[28px] h-[28px] text-warning"
                                            />
                                            <div className="ml-auto">
                                                <Tippy
                                                as="div"
                                                className="cursor-pointer bg-success py-[3px] flex rounded-full text-white text-xs pl-2 pr-1 items-center font-medium"
                                                content="12% Higher than last month"
                                                >
                                                <Lucide icon="ChevronUp" className="w-4 h-4 ml-0.5" />
                                                </Tippy>
                                            </div>
                                            </div>
                                            <div className="mt-6 text-3xl font-medium leading-8">
                                            2,149
                                            </div>
                                            <div className="mt-1 text-base text-slate-500">
                                            Total Cars
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {/* END: General Report */}
                                    <br/>
                               {/* BEGIN: Basic Tab */}
                                <PreviewComponent className="intro-y box container">
                                    {({ toggle }) => (
                                    <>
                                        <div className="p-5">
                                        <Preview>

                                        <Tab.Group>
                                        <div className="col-span-12 mt-8">
                                            <div className="grid grid-cols-12 gap-6 mt-5">
                                                <div className="col-span-12 intro-y">
                                                    <div className="flex items-center justify-center">
                                                        <Tab.List variant="tabs">
                                                            <Tab>
                                                            <Tab.Button className="w-full py-2 home-tabs" as="button">
                                                                Car Search
                                                            </Tab.Button>
                                                            </Tab>
                                                            <Tab>
                                                            <Tab.Button className="w-full py-2 home-tabs" as="button">
                                                                Mileage Verification
                                                            </Tab.Button>
                                                            </Tab>
                                                        </Tab.List>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                            <Tab.Panels className="border-b border-l border-r border-t">
                                                <Tab.Panel className="p-5 leading-relaxed">
                                                <div className="flex items-center justify-center">
                                                    <form className="flex items-center justify-center" noValidate onSubmit={handleFormSubmission}>
                                                        <input type="text" className="intro-x login__input form-control py-3 px-4 block w-40" placeholder="Number Plate" onChange={(e) => setNumberPlate(e.target.value)} />
                                                        <button className="btn btn-primary py-2 px-4 w-full xl:w-32 xl:mr-3 align-top ml-5" disabled={showLoader}>
                                                            {showLoader && (
                                                                <svg width="25" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" className="w-8 h-8">
                                                                    <g fill="none" fillRule="evenodd" strokeWidth="4">
                                                                        <circle cx="22" cy="22" r="1">
                                                                            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                                                            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                                                        </circle>
                                                                        <circle cx="22" cy="22" r="1">
                                                                            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                                                                            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                                                                        </circle>
                                                                    </g>
                                                                </svg>
                                                            )}
                                                            Search
                                                            </button>
                                                        </form>
                                                </div>
                                                    
                                                </Tab.Panel>
                                                <Tab.Panel className="p-5 leading-relaxed">
                                                    Coming soon
                                                </Tab.Panel>
                                            </Tab.Panels>
                                            </Tab.Group>
                                        </Preview>
                                       
                                        </div>
                                    </>
                                    )}
                                </PreviewComponent>
                                {/* END: Basic Tab */}
                            </div>
                        </div>
                    </div>
            </div>
                
                
        </div>

    </div>
        
    );
}

export default Header